<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="user_uuid"
                :label="$t('user')"
                class="autocomplete-new-entity"
                :class="form.model_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getUserKey()"
                  v-loading="getUserKey() === 0"
                  v-model="form.user_uuid"
                  :remote-method="searchUsers"
                  :placeholder="$t('select_an_user')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchUsers(form.user)"
                  style="width: 100%"
                  @change="$emit('input'), onUserChange($event)"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewUserDialog = true"
                />
                <Icons
                  v-if="form.user_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/configuration/users', {
                      uuid: form.user_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <UtilsDocumentNumbers
              ref="documentNumberComponent"
              :documents="form.documentNumbers"
              :is-required="true"
              :background="true"
              :column-sizes="{
                span: width <= 700 ? 24 : 12,
                lg: 12,
              }"
              @documentsToCreateOrUpdateOrDelete="documentsToCreateOrUpdateOrDelete"
            />
            <ElCol v-if="width > 700" :span="16" :lg="16" />
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewUserDialog"
      :close-on-click-modal="false"
      :width="width <= 600 ? '100%' : '70vw'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewUserDialog = false), createUser($event)"
        @cancel="showNewUserDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_EMPLOYEE from '~/graphql/employees/mutation/createEmployee.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  user_uuid: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()

const ruleFormRef = ref()
const loading = ref(false)
const showNewUserDialog = ref(false)
const documentNumbers = ref([])
const emergencyContacts = ref([])
const documentNumberComponent = ref(null)

onMounted(() => {
  initialUsers()
})

const inputRules = reactive({
  user_uuid: [
    {
      required: true,
      message: t('user_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})


const rules = {
  user_uuid: { required }
}

const v$ = useVuelidate(rules, form)

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })

  const documentsValid = await documentNumberComponent.value?.validateAllDocuments()
  if (!documentsValid) {
    $showError(t('please_review_documents_section'))
    return
  }

  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    user_uuid: form.user_uuid,
    document_numbers: documentNumbers.value,
    emergency_contacts: emergencyContacts.value,
    enabled: true
  }
  const response = await mutation(CREATE_EMPLOYEE, variables)
  if (response.error) {
    if (response.error.message.includes('input.document number')) {
      $showError(t('document_number_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
    loading.value = false
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createEmployee ?? null)
    loading.value = false
  }
}

const onUserChange = async (uuid) => {
  if (getSearchUsers()?.length && uuid) {
    const user = getSearchUsers().find((item) => item.value === uuid)
    const results = await usersAutocomplete({
      search: user?.label?.split(' ')[0] ?? ''
    })
    if (results?.getAllUsers?.data?.find((item) => item.uuid === uuid)) {
      const item = results.getAllUsers.data.find((item) => item.uuid === uuid)
      form.first_name = item.first_name
      form.last_name = item.last_name
    }
  }
}

const documentsToCreateOrUpdateOrDelete = (newDocuments) => {
  documentNumbers.value = newDocuments
}

const emergencyContactsToCreateOrUpdateOrDelete = (newEmergencyContacts) => {
  emergencyContacts.value = newEmergencyContacts
}
</script>

<style>
</style>